<template>
  <div class="permission-list-page">
    <div class="permission-header">
      <el-button type="primary" @click="handleClickCreate">新增</el-button>
    </div>
    <div class="permission-content">
      <el-table :data="adminGroupList.items" border stripe>
        <el-table-column
          v-for="col in columns"
          :prop="col.id"
          :key="col.id"
          :label="col.label"
          :width="col.width"
        ></el-table-column>
        <el-table-column label="操作" width="400" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click="handleClickEdit(scope.row)"
            >
              编辑
            </el-button>
            <el-button
              size="mini"
              type="danger"
              @click="handleClickDel(scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChange"
        @current-change="currentChange"
        :current-page="page"
        :page-sizes="[20, 40, 80, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="adminGroupList.total"
        background
        style="margin-top: 20px;"
      >
      </el-pagination>
    </div>
    <el-dialog
      :title="optionName"
      :visible.sync="optionDialogVisible"
      width="30%"
      @close="hideDialog"
    >
      <div class="create-content">
        <el-row type="flex" align="middle">
          <el-col :span="6">权限组名：</el-col>
          <el-col :span="18">
            <el-input v-model="adminGroupName" placeholder="请输入"></el-input>
          </el-col>
        </el-row>
        <el-row style="margin-top: 20px;">
          <el-col :span="6">权限选择：</el-col>
          <el-col :span="12">
            <el-tree
              :data="menuTree"
              :props="defaultProps"
              show-checkbox
              node-key="id"
              default-expand-all
              :default-checked-keys="defaultCheckedKeys"
              ref="tree"
            >
            </el-tree>
          </el-col>
        </el-row>
      </div>
      <span slot="footer">
        <el-button @click="hideDialog">取 消</el-button>
        <el-button type="primary" @click="option">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getMenu } from "@/api/user";
import { Message } from "element-ui";

const object2Array = (obj) => Object.keys(obj).map((key) => obj[key]);

const getMenuCheckedKeys = (checkedMenuList) => {
  let res = [];

  checkedMenuList.forEach((item) => {
    res.push(item.code);
    if (item.children) {
      res = [...res, ...getMenuCheckedKeys(item.children)];
    }
  });

  return res;
};

// 接口返回菜单数据 => el-tree data 数据
const menuInfilter = (menuList) => {
  const res = [];

  menuList.forEach((menu) => {
    const tmp = {
      ...menu,
      id: menu.code,
    };

    if (tmp.children) {
      tmp.children = menuInfilter(tmp.children);
    }

    res.push(tmp);
  });

  return res;
};

// el-tree 选中数据 => 用户权限菜单数据
const menuOutFilter = (allMenus, checkKeys, halfCheckKeys) => {
  let res = {};
  Object.keys(allMenus).forEach((key) => {
    if (checkKeys.includes(key)) {
      res[key] = allMenus[key];
    } else if (halfCheckKeys.includes(key)) {
      let tmp = allMenus[key];
      tmp.children = tmp.children.filter((submenu) =>
        checkKeys.includes(submenu.code)
      );
      res[key] = tmp;
    }
  });
  return res;
};

export default {
  data() {
    return {
      columns: [
        { id: "id", label: "权限组ID" },
        { id: "name", label: "权限组名" },
        { id: "created_at", label: "创建时间" },
        { id: "updated_at", label: "更新时间" },
      ],
      optionDialogVisible: false,
      adminGroupName: "",
      menuTree: [],
      allMenus: {},
      defaultProps: {
        children: "children",
        label: "name",
      },
      defaultCheckedKeys: [],
      optionType: "create",
      editAdminGroup: {},
      page: 1,
      pageSize: 20,
    };
  },
  mounted() {
    this.getAdminGroupList();
    this.getMenu();
  },
  computed: {
    ...mapGetters(["adminGroupList"]),
    optionName() {
      const nameMap = {
        create: "新建权限组",
        edit: "编辑权限组",
      };
      return nameMap[this.optionType];
    },
  },
  methods: {
    async getMenu() {
      try {
        const { data } = await getMenu();
        const menuObject = data.data;

        if (!menuObject) {
          return;
        }

        this.allMenus = menuObject;
        this.menuTree = menuInfilter(object2Array(menuObject));
      } catch (error) {
        console.log(error);
      }
    },
    getAdminGroupList() {
      this.$store.dispatch("user/getAdminGroupList", {
        page: this.page,
        page_size: this.pageSize,
      });
    },
    handleClickCreate() {
      this.optionType = "create";
      this.optionDialogVisible = true;
    },
    handleClickEdit(item) {
      this.optionType = "edit";
      this.optionDialogVisible = true;
      this.adminGroupName = item.name;
      this.editAdminGroup = item;

      let checkedKeys = []; // getMenuCheckedKeys();
      object2Array(item.codes).forEach((item) => {
        checkedKeys = [...checkedKeys, ...getMenuCheckedKeys(item.children)];
      });
      this.defaultCheckedKeys = checkedKeys;
    },
    async handleClickDel(item) {
      try {
        await this.$confirm(
          `<div>
            <p>确认要删除${item.name}吗?</p>
            <p>删除不可恢复，属于这个权限组的用户将回到未配置权限组状态</p>
          </div>`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
            dangerouslyUseHTMLString: true,
          }
        );
        this.del(item);
      } catch (error) {
        // 取消删除
        console.log(error);
      }
    },
    option() {
      const actionMap = {
        create: this.create,
        edit: this.edit,
      };
      actionMap[this.optionType]();
    },
    async edit() {
      // 生成参数
      const data = {
        ...this.editAdminGroup,
        name: this.adminGroupName,
        codes: this.getCheckedMenus(),
      };
      // 请求更新
      const success = await this.$store.dispatch("user/updateAdminGroup", data);
      // 页面响应
      this.hideDialog();
      this.optionToast(success);
    },
    async create() {
      // 生成参数
      const adminGroup = {
        name: this.adminGroupName,
        codes: this.getCheckedMenus(),
      };
      // 请求新建
      const success = await this.$store.dispatch(
        "user/createAdminGroup",
        adminGroup
      );
      // 页面响应
      this.hideDialog();
      this.optionToast(success);
    },
    async del(item) {
      // 请求删除
      const success = await this.$store.dispatch(
        "user/deleteAdminGroup",
        item.id
      );
      // 页面响应
      this.optionToast(success);
    },
    hideDialog() {
      this.optionDialogVisible = false;
      this.adminGroupName = "";
      this.$refs.tree.setCheckedKeys([]);
      this.editAdminGroup = {};
    },
    getCheckedMenus() {
      const checkKeys = this.$refs.tree.getCheckedKeys();
      const halfCheckKeys = this.$refs.tree.getHalfCheckedKeys();
      const authMenus = menuOutFilter(this.allMenus, checkKeys, halfCheckKeys);
      return JSON.stringify(authMenus);
    },
    optionToast(success) {
      if (success) {
        Message.success({
          message: "操作成功",
          duration: 2000,
        });
        this.getAdminGroupList();
      } else {
        Message.error({
          message: "操作失败，请稍后再试",
          duration: 2000,
        });
      }
    },
    sizeChange(val) {
      this.pageSize = val;
      this.getAdminGroupList();
    },
    currentChange(val) {
      this.page = val;
      this.getAdminGroupList();
    },
  },
};
</script>

<style lang="less" scoped>
.permission-list-page {
  margin: 12px 0;

  .permission-header {
    margin-bottom: 12px;
  }
}
</style>
