<template>
  <div class="account-page">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="账户" name="account">
        <AccountList />
      </el-tab-pane>
      <el-tab-pane label="权限组" name="permission">
        <permissionList />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import AccountList from "./accountList";
import PermissionList from "./permissionList";

export default {
  data() {
    return {
      activeName: "account",
    };
  },
  components: {
    AccountList,
    PermissionList,
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
  },
};
</script>

<style lang="less" scoped>
.account-page {
  margin: 0;
  padding: 24px;
}
</style>
