<template>
  <div class="account-list-page">
    <el-button type="primary" icon="el-icon-plus" @click="showAdd">
      新增
    </el-button>
    <el-table
      :data="adminList.items"
      style="width: 100%"
      :default-sort="{ prop: 'created_at', order: 'descending' }"
    >
      <el-table-column prop="id" label="账户ID" width="180"> </el-table-column>
      <el-table-column prop="username" label="账户名" width="180">
      </el-table-column>
      <el-table-column prop="created_at" label="创建时间" width="180">
      </el-table-column>
      <el-table-column prop="status" label="状态" width="180">
        <template slot-scope="scope">{{
          statusMap[scope.row.status]
        }}</template>
      </el-table-column>
      <el-table-column prop="updated_at" label="更新时间" width="180">
      </el-table-column>
      <el-table-column prop="id" label="操作" width="240">
        <template slot-scope="scope">
          <el-button
            @click="changeCurrent(scope.row, 'Group')"
            type="text"
            size="small"
          >
            编辑组
          </el-button>
          <el-divider direction="vertical"></el-divider>
          <el-button
            @click="changeCurrent(scope.row, 'Delete')"
            type="text"
            size="small"
          >
            删除
          </el-button>
          <el-divider direction="vertical"></el-divider>
          <el-button
            @click="changeCurrent(scope.row, 'Status')"
            type="text"
            size="small"
          >
            {{ statusMap[-scope.row.status] }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="page"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="adminList.total"
      background
      @size-change="sizeChange"
      @current-change="currentChange"
    >
    </el-pagination>
    <!-- 新增 -->
    <el-dialog title="新建账户" :visible.sync="addForm.visible">
      <el-form :model="addForm">
        <el-form-item label="账户名" label-width="120px">
          <el-input v-model="addForm.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="权限组" label-width="120px">
          <el-select v-model="addForm.groupId" placeholder="请选择用户权限组">
            <el-option
              v-for="group in adminGroupList.items"
              :key="group.id"
              :label="group.name"
              :value="group.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancleAdd">取 消</el-button>
        <el-button type="primary" @click="submitAdd">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 编辑 -->
    <el-dialog title="编辑组" :visible.sync="showGroup" width="400">
      <el-select v-model="currentGroupId" placeholder="请选择用户权限组">
        <el-option
          v-for="group in adminGroupList.items"
          :key="group.id"
          :label="group.name"
          :value="group.id"
        ></el-option>
      </el-select>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancleEdit">取 消</el-button>
        <el-button type="primary" @click="editCurrentAdmin('group')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <el-dialog title="禁用/启用账户" :visible.sync="showStatus" width="400">
      <span>
        确定要{{ statusMap[-currentStatus] }}账户{{ currentName }}吗？
      </span>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancleEdit">取 消</el-button>
        <el-button type="primary" @click="editCurrentAdmin('status')">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <!-- 删除 -->
    <el-dialog title="提示" :visible.sync="showDelete" width="400">
      <span>确认要删除账户{{ currentName }}吗？</span>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showDelete = false">取 消</el-button>
        <el-button type="primary" @click="deleteCurrentAdmin(currentId)">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <!-- 重置密码 -->
    <el-dialog title="重置密码" :visible.sync="showReset">
      <span>确定重置账户{{ currentName }}的密码吗？</span>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showReset = false">取 消</el-button>
        <el-button type="primary" @click="resetAdminPassword">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 展示用户名密码 -->
    <el-dialog title="用户信息" :visible.sync="userInfo.visible" width="400">
      <p>用户名：{{ userInfo.userName }}</p>
      <p>密码：{{ userInfo.password }}</p>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  addAdministrator,
  editAdministrator,
  deleteAdmin,
  resetPassword,
} from "@/api/user";
export default {
  data() {
    return {
      page: 1,
      pageSize: 20,
      addForm: {
        visible: false,
        name: "",
        groupId: "",
      },
      userInfo: {
        visible: false,
        userName: "",
        password: "",
      },
      showDelete: false,
      showGroup: false,
      showStatus: false,
      showReset: false,
      currentId: "",
      currentStatus: "",
      currentGroupId: "",
      currentName: "",
      statusMap: {
        1: "启用",
        "-1": "禁用",
      },
    };
  },
  computed: {
    ...mapGetters(["adminList", "adminGroupList"]),
  },
  watch: {},
  mounted() {
    this.getAdminList();
  },
  methods: {
    // 管理员列表
    getAdminList() {
      const query = {};
      query.page = this.page;
      query.page_size = this.pageSize;
      // if (this.keyword) {
      //   query.keyword = this.keyword;
      // }
      this.$store.dispatch("user/getAdminList", query);
    },
    // 分组列表
    getAdminGroupList() {
      const query = {};
      query.page = this.page;
      query.page_size = this.pageSize;
      this.$store.dispatch("user/getAdminGroupList", query);
    },
    // 分页
    sizeChange(size) {
      this.pageSize = size;
      this.getAdminList();
    },
    currentChange(page) {
      this.page = page;
      this.getAdminList();
    },
    // 新增
    showAdd() {
      this.addForm.visible = true;
      this.getAdminGroupList();
    },
    cancleAdd() {
      this.addForm = {
        visible: false,
        name: "",
        groupId: "",
      };
    },
    async submitAdd() {
      const query = {};
      query.username = this.addForm.name;
      query.group_id = this.addForm.groupId;
      try {
        const data = await addAdministrator(query);
        this.userInfo = {
          visible: true,
          userName: this.addForm.name,
          password: data.data.data.password,
        };
        this.cancleAdd();
        this.getAdminList();
      } catch (error) {}
    },
    // 编辑
    changeCurrent(current, key) {
      this.currentId = current.id;
      this.currentStatus = current.status;
      this.currentGroupId = current.group_id;
      this.currentName = current.username;
      this[`show${key}`] = true;
    },
    cancleEdit() {
      this.currentId = "";
      this.currentStatus = "";
      this.currentGroupId = "";
      this.currentName = "";
      this.showStatus = false;
      this.showGroup = false;
    },
    editCurrentAdmin(key) {
      if (key == "group") {
        this.submitEdit({ id: this.currentId, group_id: this.currentGroupId });
      } else if (key == "status") {
        this.submitEdit({ id: this.currentId, status: -this.currentStatus });
      }
    },
    // status,group_id,password,id
    async submitEdit(json) {
      try {
        const data = await editAdministrator(json);
        this.$message({
          message: "操作成功",
          duration: 3000,
          type: "success",
        });
        this.cancleEdit();
        this.getAdminList();
      } catch (error) {}
    },
    // 删除
    async deleteCurrentAdmin(id) {
      try {
        const data = await deleteAdmin(id);
        this.showDelete = false;
        this.$message({
          message: "删除成功",
          duration: 3000,
          type: "success",
        });
        this.getAdminList();
      } catch (error) {}
    },

    // 重置密码
    async resetAdminPassword() {
      try {
        const data = await resetPassword({ id: this.currentId });
        this.showReset = false;
        this.$message({
          message: "重置成功",
          duration: 3000,
          type: "success",
        });
        console.log(data.data.data.password);
        this.userInfo = {
          visible: true,
          userName: this.currentName,
          password: data.data.data.password,
        };
        this.getAdminList();
      } catch (error) {}
    },
  },
};
</script>

<style lang="less" scoped>
.account-list-page {
  margin: 24px;
}
.el-pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
</style>
